import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Suspense, useEffect } from 'react';
import useSWR from 'swr'
import { getStrapiURL } from './utils/variables';
import { createRoutes } from './utils/menuHelper';
import Layout from './components/layout';
import Wine from './pages/wine';
import { loading } from './components/loading-spinner';
import {initTE, Collapse, Ripple } from "tw-elements";

const fetchMenu = (...args) => fetch(...args).then(res => res.json())

function App() {
  const { data, error, isLoading } = useSWR(getStrapiURL("/api/menus/1?populate=*"), fetchMenu)
  const menuItems = data?.data?.attributes?.items?.data;

    useEffect(() => {
      initTE({ Collapse, Ripple });
    }, []);

  if (error) return <div>failed to load</div>
  if (isLoading) return loading();

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Layout menuItems={menuItems} >
          <Routes>
            {
              createRoutes(menuItems).map((route, i) => {
                return (<Route key={i} path={route.path} name={route.name} element={route.element} />)
              })
            }
            <Route key={99999} path="/wines/:id" element={<Wine />} />
          </Routes>
        </Layout>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
