import { useParams } from "react-router-dom";
import useSWR from 'swr'
import { getStrapiURL } from '../utils/variables';
import { loading } from "../components/loading-spinner";
import BlurImage from "../components/blur-image";
import RatingPic from '../assets/images/wine-glass.png'

const fetchPage = (...args) => fetch(...args).then(res => res.json())

const Wine = () => {
    const params = useParams();
    const { data, error, isLoading } = useSWR(getStrapiURL(`/api/wines?filters[Identifier][$eq]=${params.id}&populate=*`), fetchPage)
    const wine = data?.data[0]?.attributes;

    if (error) return <div>failed to load</div>
    if (isLoading) return loading();

    console.log(wine);

    return (
        <div className="container my-12 mx-auto md:px-6">
            <section className="mb-32 text-center">
                <div className="flex-row items-center lg:flex">
                    <div className="p-6">
                        <img
                            src={getStrapiURL() + wine.Pictures.data[0].attributes.url}
                            style={{ height: "100%", maxHeight: '600px', objectFit: 'cover' }}
                            alt=""
                            loading="lazy" />
                    </div>
                    <div className="w-full" style={{ textAlign: 'left' }}>
                        <p className="">{wine.winery.data.attributes.Title}</p>
                        <h1 className="font-bold" style={{ fontSize: '1.2rem' }}>{wine.Title}</h1>
                        <p className="">{wine.Vintage}</p>
                        <table className="table-auto">
                            <tbody>
                                <tr>
                                    <td className="border px-4 py-2">Land</td>
                                    <td className="border px-4 py-2">{wine.winecountry.data.attributes.Country}</td>
                                </tr>
                                <tr>
                                    <td className="border px-4 py-2">Region</td>
                                    <td className="border px-4 py-2">{wine.wineregion.data.attributes.Region}</td>
                                </tr>
                                <tr>
                                    <td className="border px-4 py-2">Producent</td>
                                    <td className="border px-4 py-2">{wine.winery.data.attributes.Title}</td>
                                </tr>
                                <tr>
                                    <td className="border px-4 py-2">Pris</td>
                                    <td className="border px-4 py-2">{wine.price} kr.</td>
                                </tr>
                                <tr>
                                    <td className="border px-4 py-2">Druer</td>
                                    <td className="border px-4 py-2">
                                        {wine.grapes.data.map((grape, index) => {
                                            return (
                                                <span key={index}
                                                    className="inline-block whitespace-nowrap rounded-full bg-primary-100 px-[0.65em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-[0.75em] font-bold leading-none text-primary-700 mr-2 ml-2">
                                                    {grape.attributes.Title}
                                                </span>
                                            )
                                        })}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {
                            wine.dealer &&
                            (<p>Vinen er købt hos <a href={wine.dealer} style={{ textDecoration: 'underline', color: '#5f5e59' }}>{wine.dealerName}</a></p>)
                        }
                        <div className="flex mt-2">
                            <img src={RatingPic} width={35} height={35} alt="wineglass" />
                            <p className="ml-2 text-lg font-bold" style={{ marginTop: 'auto', marginBottom: 'auto' }}>{wine.Rating} / 7</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )

}

export default Wine;