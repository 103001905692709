import useSWR from 'swr'
import { getStrapiURL } from '../utils/variables';
import { Link } from 'react-router-dom';

const fetchPage = (...args) => fetch(...args).then(res => res.json())

const Home = () => {
    const { data, error, isLoading } = useSWR(getStrapiURL("/api/homepage"), fetchPage)
    const homepage = data?.data?.attributes;

    if (error) return <div>failed to load</div>
    if (isLoading) return <div>loading...</div>

    return (
        // <div className="uk-section">
        //   <div className="uk-container uk-container-large">
        //     <h1>{}</h1>
        //   </div>
        // </div>
        <section className="relative overflow-hidden bg-cover bg-no-repeat bg-topbanner bg-gray-500 bg-blend-multiply" style={{backgroundPositionY: '30%'}}>
            <div className="m-0 h-full w-full overflow-hidden bg-fixed">
            <div className="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-56">
                <h1 className="mb-4 text-white text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl">Velkommen til Jydeklubben!</h1>
                <p className="mb-8 text-white text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48">Jydeklubben består af vinelskere frem for vineksperter, hvor hyggen og det at finde den rette flaske til den rigtige pris er vores fokus.</p>
                <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                    <Link href="#" className="text-white inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-[#908a87] hover:bg-[#AA9A9A] focus:ring-4 focus:ring-blue-300">
                        Seneste bedømmelser
                        <svg aria-hidden="true" className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </Link>
                    <Link to="/about" className="text-white inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-[#2a332b] focus:ring-4 focus:ring-gray-100">
                        Hvem er vi?
                    </Link>
                </div>
            </div>
            </div>
        </section>
    )
}

export default Home