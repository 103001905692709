import useSWR from 'swr'
import { getStrapiURL } from '../utils/variables';
import BlurImage from '../components/blur-image';
import RatingPic from '../assets/images/wine-glass.png'
import { loading } from '../components/loading-spinner';
import { Link } from 'react-router-dom';

const fetchPage = (...args) => fetch(...args).then(res => res.json())

const Wines = () => {
    const { data, error, isLoading } = useSWR(getStrapiURL("/api/wines?populate=*"), fetchPage)
    const page = data?.data?.attributes;

    if (error) return <div>failed to load</div>
    if (isLoading) return loading();

    return (<div className="container my-12 mx-auto md:px-6">
        <section className="mb-32 text-center">
            <h2 className="mb-32 text-3xl font-bold ">
                Alle bedømmelser
            </h2>
            <div className="grid gap-x-6 md:grid-cols-3 lg:gap-x-12">
                {data.data.map((wine) => {
                    return (
                        <div key={wine.attributes.Identifier} className="mb-12 md:mb-12 hover:scale-105 ease-in duration-200">
                            <Link to={`/wines/${wine.attributes.Identifier}`} >

                                <div className="container mx-auto">
                                    <section className="mb-0">
                                        <div
                                            className="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
                                            <div className="flex flex-wrap items-center">
                                                <div className="block w-full shrink-0 grow-0 basis-auto lg:flex">
                                                    <BlurImage imgSrc={wine.attributes.Pictures.data[0].attributes} className="mx-auto" imgAlt="Avatar" height="20rem" width='' styleClasses="w-full rounded-t-lg object-cover" />
                                                </div>
                                                <div className="w-full shrink-0 grow-0 basis-auto">
                                                    <div className="px-6 py-6 md:px-12">
                                                        <p className="">{wine.attributes.winery.data.attributes.Title}</p>
                                                        <h2 className="font-bold" style={{fontSize: '1.2rem'}}>{wine.attributes.Title}</h2>
                                                        <p className="">{wine.attributes.Vintage}</p>

                                                        {wine.attributes.grapes.data.map((grape, index) => {
                                                            return (
                                                                <span key={index}
                                                                    className="inline-block whitespace-nowrap rounded-full bg-primary-100 px-[0.65em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-[0.75em] font-bold leading-none text-primary-700">
                                                                    {grape.attributes.Title}
                                                                </span>
                                                            );
                                                        })}
                                                        <div className="flex mt-2" style={{ justifyContent: 'center' }}>
                                                            <img src={RatingPic} width={35} height={35} alt="wineglass" />
                                                            <p className="ml-2 text-lg font-bold" style={{ marginTop: 'auto', marginBottom: 'auto' }}>{wine.attributes.Rating} / 7</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                            </Link>
                        </div>
                    )
                })}
            </div>
        </section>
    </div>
    );

}

export default Wines