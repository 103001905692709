import RatingPic from '../assets/images/wine-glass.png'

const RatingDescription = ({ glasses, description }) => {
    return (<>

        <div className="flex" style={{ width: 'fit-content', margin: 'auto' }}>
            {
                Array(glasses).fill().map((g, i) => {
                    return (<img key={i} src={RatingPic} width={35} height={35} alt="wineglass" style={{ margin: 'auto' }} />)
                })
            }
        </div>
        <p className="mb-12">{description}</p>
    </>)
}

export default RatingDescription