import useSWR from 'swr'
import { getStrapiURL } from '../utils/variables';
import BlurImage from '../components/blur-image';
import VivinoIcon from "../assets/images/VivinoIcon.png";
import { loading } from '../components/loading-spinner';

const fetchPage = (...args) => fetch(...args).then(res => res.json())

const About = () => {
    const { data, error, isLoading } = useSWR(getStrapiURL("/api/about"), fetchPage)
    const { data: members, error: membersError, isLoading: membersIsLoading } = useSWR(getStrapiURL("/api/clubmembers?populate=*"), fetchPage)
    const page = data?.data?.attributes;

    if (error) return <div>failed to load</div>
    if (isLoading) return loading();

    return (
        <div className="container my-12 mx-auto md:px-6">
            <section className="mb-32 text-center">
                <h2 className="mb-8 text-3xl font-bold">
                    {page.Title}
                </h2>
                <p className="mb-12">{page.BodyText}</p>
                <div className="grid gap-x-6 md:grid-cols-3 lg:gap-x-12">
                    {
                        membersIsLoading && <div>loading...</div>
                    }
                    {members?.data?.map((member, i) => {
                        return (<>
                            <div className="mb-6 lg:mb-6" key={i}>
                                <div className="relative rounded-lg p-6 bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]" style={{margin: 'auto'}}>
                                    <div className="flex-row items-center lg:flex">
                                        <div className="w-full shrink-0 grow-0 basis-auto" style={{width: "fit-content"}}>
                                            <BlurImage imgSrc={member.attributes.MemberProfileImage.data[0].attributes} imgAlt={member.attributes.MemberName}
                                                styleClasses="mb-6 w-full object-contain rounded-md md:my-auto lg:mb-0" height="10rem" />
                                        </div>
                                        <div className="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
                                            <h5 className="mb-2 text-lg font-bold text-left lg:ml-6">{member.attributes.MemberName}</h5>
                                            <ul className="mx-auto flex list-inside justify-center lg:justify-start">
                                                {member.attributes.MemberVivinoLink != null &&
                                                    <a href={member.attributes.MemberVivinoLink} className="lg:ml-6" target="_blank">
                                                        <img src={VivinoIcon} alt="Vivino" className="w-6 h-6" />
                                                    </a>
                                                }
                                                {
                                                    member.attributes.MemberVivinoLink == null &&
                                                    <li className="px-2 w-6 h-6">
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        );
                    })}
                </div>
            </section>
        </div>
    );
}

export default About