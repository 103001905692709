import useSWR from 'swr'
import { getStrapiURL } from '../utils/variables';
import RatingDescription from '../components/ratingdefinition';
import { loading } from '../components/loading-spinner';

const fetchPage = (...args) => fetch(...args).then(res => res.json())

const Ratingschema = () => {
    const { data, error, isLoading } = useSWR(getStrapiURL("/api/ratingschema"), fetchPage)
    const page = data?.data?.attributes;

    if (error) return <div>failed to load</div>
    if (isLoading) return loading();


    return (
        <div className="container my-12 mx-auto md:px-6">
            <section className="mb-32 text-center">
                <h2 className="mb-8 text-3xl font-bold">
                    {page.Title}
                </h2>
                <p className="mb-12">{page.BodyText}</p>

                <RatingDescription glasses={1} description={page.Glass0} />
                <RatingDescription glasses={2} description={page.Glass1} />
                <RatingDescription glasses={3} description={page.Glass2} />
                <RatingDescription glasses={4} description={page.Glass3} />
                <RatingDescription glasses={5} description={page.Glass4} />
                <RatingDescription glasses={6} description={page.Glass5} />
                <RatingDescription glasses={7} description={page.Glass6} />
            </section>
        </div>
    )
}

export default Ratingschema