import Nav from "./nav"

const Layout = ({ menuItems, children }) => {
    return (
        <>
            <Nav menuItems={menuItems} />
            {children}
        </>
    )
}

export default Layout