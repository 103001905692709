import { useEffect, useState } from "react";
import logoPng from "../assets/images/logoWhite.png"
import { Link } from "react-router-dom"
import { useLocation } from 'react-router-dom';


const Nav = ({ menuItems }) => {
    const [navbar, setNavbar] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setNavbar(false)
    }, [location]);

    return (
        <nav className={(location.pathname === "/" ? "absolute w-full" : 'relative w-full bg-[#c1b6b6]')} style={{ zIndex: 99999 }} data-te-navbar-ref>
            <div className={`justify-between px-0 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8`}>
                <div>
                    <div className="flex items-center justify-between py-3 md:py-5 md:block">
                        <div
                            className="!visible hidden flex-grow basis-[100%] items-center md:!flex lg:basis-auto">
                            <Link
                                className="mb-4 ml-5 mt-3 flex items-center text-white hover:text-white focus:text-white lg:mb-0 lg:mt-0"
                                to="/">
                                <img
                                    src={logoPng}
                                    style={{ height: "auto" }}
                                    alt=""
                                    width={50}
                                    loading="lazy" />
                                <p className="ml-5 text-2xl AnticSlab">Jydeklubben</p>
                            </Link>
                        </div>
                        <div className="md:hidden" style={{ width: '100%' }}>
                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'inline-block', position: 'relative', width: '50%' }}>
                                    <Link
                                        className="mb-4 ml-5 mt-3 flex items-center text-white hover:text-white focus:text-white lg:mb-0 lg:mt-0"
                                        to="/">
                                        <img
                                            src={logoPng}
                                            style={{ height: "auto" }}
                                            alt=""
                                            width={50}
                                            loading="lazy" />
                                        <p className="ml-5 text-2xl AnticSlab">Jydeklubben</p>
                                    </Link>
                                </div>
                                <div style={{ display: 'inline-block', position: 'relative', width: '50%', margin: 'auto' }}>
                                    <button
                                        style={{ float: 'right' }}
                                        className="p-4 mr-6 text-white rounded-md outline-none focus:border-gray-400 focus:border"
                                        onClick={() => setNavbar(!navbar)}
                                    >
                                        {navbar ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="w-6 h-6 text-white"
                                                viewBox="0 0 20 20"
                                                fill="#ffffff"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="w-6 h-6 text-white"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="#ffffff"
                                                strokeWidth={2}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M4 6h16M4 12h16M4 18h16"
                                                />
                                            </svg>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        className={`flex-1 justify-self-center pb-3 mt-0 md:block md:pb-0 md:mt-0 ${navbar ? 'block h-[calc(100vh-102px)] bg-[#c1b6b6] p-4 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]' : 'hidden'
                            }`}
                    >
                        <ul className="items-center justify-center md:flex md:space-x-6 md:space-y-0">
                            {
                                menuItems != null &&
                                menuItems.sort((x, y) => x.attributes.order - y.attributes.order).map((item, index) => {
                                    return (
                                        <Link to={item.attributes.url} onClick={() => setNavbar(false)}>
                                            <li key={index} className="p-2 md:mb-0 md:pr-2" data-te-nav-item-ref to={item.attributes.url}>
                                                <Link
                                                    className="text-white hover:text-gray-300 focus:text-white disabled:text-black/30 md:px-2 [&.active]:text-black/90"
                                                    to={item.attributes.url}
                                                    data-te-nav-link-ref
                                                    onClick={() => setNavbar(false)}
                                                >{item.attributes.title}</Link>
                                            </li>
                                        </Link>);
                                })
                            }
                        </ul>
                        <img
                            src={logoPng}
                            style={{ height: "auto", position: 'absolute', bottom: '2vh', left: '50%', transform: 'translate(-50%, 0)', display: !navbar ? 'none' : 'block' }}
                            alt=""
                            width={150}
                            loading="lazy" />
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Nav