import { Route } from "react-router-dom";
import About from "../pages/about";
import Home from "../pages/home";
import Wines from "../pages/wines";
import Ratingschema from "../pages/ratingschema";
import Wine from "../pages/wine";

const createRoutes = (menuItems) => {
    const _routes = menuItems.map((item, i) => {
        return {
            path: item.attributes.url,
            name: item.attributes.title,
            element: getComponentFromUrl(item.attributes.url)
        }
    })
    return _routes;
}

const getComponentFromUrl = (url) => {
    switch (url) {
        case '/':
            return <Home />;
        case '/about':
            return <About />;
        case '/wines':
            return <Wines />;
        case '/ratingschema':
            return <Ratingschema />;
        default:
            return null;
    }
}

export { createRoutes }